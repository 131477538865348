<template>
  <validation-observer ref="simpleRules">
    <b-form>
      <div>

        <!-- Header: Personal Info -->
        <div class="d-flex">
          <feather-icon
            icon="UserIcon"
            size="19"
          />
          <h4 class="mb-0 ml-50">
            Password Update
          </h4>
        </div>

        <!-- Form: Personal Info Form -->
        <b-form class="mt-1">
          <b-row>
            <!-- password -->
            <b-col cols="5">
              <b-form-group
                label="Password"
                label-for="a-password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="Password"
                  rules="required|min:8|password"
                >
                  <b-form-input
                    id="a-password"
                    v-model="newPassword"
                    :state="errors.length > 0 ? false:null"
                    type="password"
                    placeholder="Password"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- confirm password -->
            <b-col cols="5">
              <b-form-group
                label="Confirm Password"
                label-for="ac-password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Confirm Password"
                  rules="required|confirmed:Password"
                >

                  <b-form-input
                    id="ac-password"
                    v-model="confirmPassword"
                    :state="errors.length > 0 ? false:null"
                    type="password"
                    placeholder="Confirm Password"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

          </b-row>

          <b-row class="mt-2">
            <b-col>
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click.prevent="validationForm"
              >
                Save Changes
              </b-button>
              <b-button
                variant="outline-secondary"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              >
                Reset
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </div>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import {
  required, min, confirmed,
} from '@validations'
import router from '@/router'
import store from '@/store'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BButton,
    ValidationProvider,
    ValidationObserver,
    // BCardText,
    BFormInput,

  },
  data() {
    return {
      confirmPassword: '',
      newPassword: '',
      required,
      min,
      confirmed,
      // codePlaceholderArgument,
    }
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          store.dispatch('userStore/updateUserPassword', { pub_id: router.currentRoute.params.id, password: this.newPassword, password_confirmation: this.confirmPassword })
          // eslint-disable-next-line no-unused-vars
            .then(response => {
              this.$bvToast.toast('The details has been updated', {
                title: 'Information Updated',
                variant: 'success',
                solid: true,
              })
            })
            .catch(error => {
              this.$bvToast.toast(error.message, {
                title: 'Error Occurred',
                variant: 'danger',
                solid: true,
              })
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
