<template>
  <validation-observer ref="simpleRules">
    <div>
      <!-- User Info: Input Fields -->
      <b-form>
        <b-row>

          <!-- Field: Full Name -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Name"
              label-for="full-name"
            >
              <validation-provider
                #default="{ errors }"
                name="Name"
                rules="required"
              >
                <b-form-input
                  id="full-name"
                  v-model="userData.name"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Email -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="userData.email"
                  :state="errors.length > 0 ? false:null"
                  type="email"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Gender -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="Gender"
              label-for="gender"
              label-class="mb-1"
            >
              <b-form-radio-group
                id="gender"
                v-model="userData.gender"
                :options="genderOptions"
                value="male"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="4"
          >

            <b-form-group
              label="User Info"
              label-for="user-userinfoTitle"
            >
              <b-form-textarea
                id="textarea-default"
                v-model="userData.user_info"
                placeholder="short bio of the user"
                rows="3"
              />
            </b-form-group>

          </b-col>

          <!-- Field: Email -->

        </b-row>
      </b-form>

      <!-- Action Buttons -->
      <b-button
        variant="primary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        @click.prevent="validationForm"
      >
        Update
      </b-button>
      <b-button
        variant="outline-secondary"
        type="reset"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      >
        Reset
      </b-button>
    </div>
  </validation-observer>
</template>

<script>
/* eslint-disable no-unused-vars */
import { ValidationObserver, ValidationProvider } from 'vee-validate'

import {
  BButton, BCol, BForm, BFormGroup, BFormInput, BFormRadioGroup, BFormTextarea, BRow,
} from 'bootstrap-vue'
import store from '@/store'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormRadioGroup,
    BFormTextarea,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      data_local: this.userData,
      selected_shifts: this.userData.shifts,
      selected_shows: this.userData.shows,
      selected_positions: this.userData.positions,
      selected_role: { label: this.userData.role.role, value: this.userData.role.id },
    }
  },

  setup(props) {
    const resolveUserRoleVariant = role => {
      if (role === 'Super User') return 'danger'
      if (role === 'Admin') return 'warning'
      if (role === 'Employee') return 'success'
      if (role === 'Public') return 'info'
      return 'primary'
    }

    const statusOptions = [
      { label: 'Active', value: 1 },
      { label: 'Inactive', value: 2 },
    ]

    const genderOptions = [
      { text: 'Male', value: 'Male' },
      { text: 'Female', value: 'Female' },
      { text: 'Other', value: 'Other' },
    ]

    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.userData.avatar = base64
    })

    return {
      resolveUserRoleVariant,
      statusOptions,
      genderOptions,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
  created() {
    // this.data_local.selected_positions = this.selected_positions
    // this.data_local.selected_shows = this.selected_shows
    // this.data_local.selected_shifts = this.selected_shifts
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          store.dispatch('userStore/updateOwnProfile', this.userData)
            .then(response => {
              this.$bvToast.toast('Your details has been updated', {
                title: 'Information Updated',
                variant: 'success',
                solid: true,
              })
            })
            .catch(error => {
              this.$bvToast.toast(error.message, {
                title: 'Error Occurred',
                variant: 'danger',
                solid: true,
              })
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
