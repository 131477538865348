<template>
  <b-form>
    <b-row>
      <b-col
        v-for="socialField in socialInputs"
        :key="socialField.dataField"
        cols="12"
        md="6"
        lg="4"
      >
        <b-form-group
          :label="socialField.label"
          :label-for="socialField.dataField"
        >
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon
                size="16"
                :icon="socialField.icon"
              />
            </b-input-group-prepend>
            <b-form-input
              :id="socialField.dataField"
              v-model="userDataSocial[socialField.dataField]"
              type="url"
            />
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col class="mt-2">
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click.prevent="validationForm"
        >
          Save Changes
        </b-button>
        <b-button
          variant="outline-secondary"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
          Reset
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend,
} from 'bootstrap-vue'
import store from '@/store'
import router from '@/router'

export default {
  components: {
    BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    userDataSocial() {
      return {
        twitter: this.userData.social !== null ? this.userData.social.twitter : 'https://www.twitter.com/',
        facebook: this.userData.social !== null ? this.userData.social.facebook : 'https://www.facebook.com/',
        instagram: this.userData.social !== null ? this.userData.social.instagram : 'https://www.instagram.com/',
      }
    },
  },
  setup() {
    // ? dataField is field name of `userDataSocial` object
    const socialInputs = [
      {
        icon: 'TwitterIcon',
        dataField: 'twitter',
        label: 'Twitter',
      },
      {
        icon: 'FacebookIcon',
        dataField: 'facebook',
        label: 'facebook',
      },
      {
        icon: 'InstagramIcon',
        dataField: 'instagram',
        label: 'instagram',
      },
    ]

    return {
      socialInputs,
    }
  },
  methods: {
    validationForm() {
      // eslint-disable-next-line
          store.dispatch('userStore/updateUserSocial', { pub_id: router.currentRoute.params.id, userSocial: this.userDataSocial })
      // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.$bvToast.toast('The details has been updated', {
            title: 'Information Updated',
            variant: 'success',
            solid: true,
          })
        })
        .catch(error => {
          this.$bvToast.toast(error.message, {
            title: 'Error Occurred',
            variant: 'danger',
            solid: true,
          })
        })
    },
  },
}
</script>

<style>

</style>
